/* eslint-disable no-shadow */

import { APIFILEDOWNLOADPDFGET, APIGET } from '@/utils/apiHelper';

const namespaced = true;

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
/**
 *
 * @access to local state: context.state.propertyName
 * @access to local getters: context.getters.getterName
 * @access to local mutations: context.commit('mutationName')
 * @access to another module state: context.rootState.moduleName.propertyName
 * @access to another module getter: context.rootGetters['moduleName/getterName']
 * @access to another module action: context.dispatch('moduleName/actionName', credentials, { root: true })
 * @access to another module mutation: context.commit('moduleName/mutationName', credentials, { root: true })
 *
 */
const actions = {
  async GetGSMWorksheetDetails(context, credentials) {
    const response = await APIGET(`proxy-gsm-api-notrt/${credentials}`, {}, true, 'CRM');
    return response.data;
  },
  async ShowReturnWorksheetDocument(context, credentials) {
    return await APIFILEDOWNLOADPDFGET(
      `proxy-gsm-api-notrt/return-worksheet/${credentials.gsmWorksheetId}/${credentials.documentId}/${credentials.lang}`,
      {},
      true,
      'CRM'
    );
  }
};

// mutations
const mutations = {
  //
};

const modules = {
  //
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules,
};
